import React from "react";
import { Link } from "react-router-dom";
import LogoBVDesktop from "../../assets/logoBV.png";
import LogoBVMobile from "../../assets/logoBV Mobile.png";

function Salesmang() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Middle-East / Pakistan Sales Manager
        </h1>

        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>

        <h2 className="text-xl font-medium mb-4 text-center">
          Job Description
        </h2>
        <p className="text-gray-700 mb-4">
          We are looking for a Sales Manager who is willing to support our
          growing business to expand operations to the Middle Eastern regions.
          The person should be familiar and experienced working as Sales Manager
          in Pakistan and the Middle-Eastern regions, within the ERP consultancy
          and digital marketing Branche.
        </p>

        <h2 className="text-xl font-medium mb-4 text-center">
          Key Responsibilities:
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Achieve growth and hit sales targets for ERP implementation, Data
            analytics, App development and Digital marketing related projects in
            the Middle-East and Pakistan.
          </li>
          <li>
            Develop a business plan and sales strategy for the market that
            ensures attainment of company sales goals and profitability.
          </li>
          <li>
            Develop a strong portfolio of new customers, whilst maintaining
            strong relationships with our current customers.
          </li>
          <li>Create B2B long term partnerships to support sales growth.</li>
          <li>
            Present sales, revenue and expenses reports and realistic forecasts
            to the management team.
          </li>
          <li>
            Identify emerging markets and market shifts while being fully aware
            of new products and competition status.
          </li>
          <li>
            Initiate and coordinate the development of action plans to penetrate
            new markets.
          </li>
          <li>
            Plan and execute cold calls and cold emails including follow up.
          </li>
          <li>
            Assist in the development and implementation of marketing plans as
            needed.
          </li>
          <li>
            Provide timely, accurate, competitive pricing on all completed
            prospect applications submitted for pricing and approval, while
            striving to maintain maximum profit margin.
          </li>
          <li>Create and conduct proposal presentations and RFP responses.</li>
          <li>Control expenses to meet budget guidelines.</li>
          <li>
            Adhere to all company policies, procedures and business ethics codes
            and ensures that they are communicated and implemented within the
            team.
          </li>
          <li>
            Demonstrate the ability to interact and cooperate with all company
            employees.
          </li>
          <li>
            Ensure that all business activity is conducted in line with company
            values, policies and ethics codes of conduct.
          </li>
          <li>
            Undertake any other activity as reasonably requested by management.
          </li>
        </ul>

        <h2 className="text-xl font-medium mb-4 text-center">Requirements</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>BS/MS degree in sales & marketing or economics backgrounds.</li>
          <li>
            Excellent written and verbal English and Arabic communications
            skills.
          </li>
          <li>Experience in ERP and digital marketing branches.</li>
          <li>
            Successful previous experience as a sales representative or sales
            manager, consistently meeting or exceeding targets.
          </li>
          <li>
            Committed to continuous education through workshops, seminars and
            conferences.
          </li>
          <li>
            Demonstrated ability to communicate, present and influence credibly
            and effectively at all levels of the organization.
          </li>
          <li>Proven ability to drive the sales process from plan to close.</li>
          <li>Strong business sense and industry expertise.</li>
          <li>
            Minimum 5 years of related work experience preferably in ERP / App
            development / Digital services Industry.
          </li>
          <li>
            Proficient use of MS Office applications (Excel, PowerPoint, Word).
          </li>
          <li>Proficient in using CRM systems.</li>
          <li>Proficiency with lead generation tools.</li>
          <li>
            Knowledge of start-ups, scale-ups and venture capitals of Middle
            Eastern markets.
          </li>
          <li>
            Analytical & Innovative thinking leading to flawless execution.
          </li>
        </ul>

        <h2 className="text-xl font-medium mb-4 text-center">What we offer:</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Competitive Salary.</li>
          <li>Sales Target Bonus.</li>
          <li>A dynamic team.</li>
          <li>Ability to work independently and share your ideas.</li>
          <li>Growth opportunities.</li>
        </ul>

        <p className="text-gray-700 mb-4">
          General Information: The above noted job description is not intended
          to be an exhaustive list of all duties and responsibilities, but
          rather to give a general sense of the responsibilities and
          expectations of the job. As the nature of business demands change so,
          too, may the essential functions of this specific position and/or the
          skills and abilities required.
        </p>
      </div>
    </div>
  );
}

export default Salesmang;
