import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV.png";
import LogoBVMobile from "../../assets/logoBV Mobile.png";

function Webdev() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Technical Buyer
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Job Description
        </h2>
        <p className="text-gray-700 mb-4">
          As a Technical Buyer, you will be involved in New Product
          Introductions (NPIs), responsible for developing purchasing
          specifications and handling tasks such as supplier selection,
          quotation processes, negotiations, contracting, and placing purchase
          orders. You play a crucial role in the success of our projects by
          ensuring that the right materials are available on time and within
          budget. You will need a ‘can-do’ attitude and flexibility to switch
          between strategic and operational tasks. The ultimate goal is to
          ensure smooth and profitable series production.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Responsibilities
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Negotiating with suppliers on prices, terms, and delivery schedules.
          </li>
          <li>
            Working closely with project managers to align the purchasing
            strategy with project goals.
          </li>
          <li>
            Documenting administrative matters such as meeting minutes, RFQs,
            and Pos.
          </li>
          <li>
            Proactively seeking opportunities for cost savings and process
            improvements.
          </li>
          <li>
            Monitoring planning and deliverables, keeping internal and external
            stakeholders informed.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Requirements</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>A bachelor’s or master’s degree in a relevant field.</li>
          <li>Experience in the technical sector is essential.</li>
          <li>Experienced in negotiations.</li>
          <li>Proficiency with Excel and ERP systems.</li>
          <li>Strong communication skills in English.</li>
          <li>
            At least 2 years of experience in technical and tactical purchasing.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Offer</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>A challenging role in a high-tech environment.</li>
          <li>Hybrid working.</li>
          <li>Laptop and phone.</li>
          <li>Quarterly profit-sharing arrangement.</li>
          <li>Training opportunities within and outside our organization.</li>
          <li>Market-conform salary.</li>
          <li>CAO Metalektro, including 27 vacation days and 13 ADV days.</li>
        </ul>
      </div>
    </div>
  );
}

export default Webdev;
