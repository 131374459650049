import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import ContactUs from "./pages/ContactUs";
import SalesManager from "./pages/SalesManager";
import TravelAgent from "./pages/TravelAgent";
import TechnicalBuyer from "./pages/TechnicalBuyer";
const Home = React.lazy(() => import("./pages/Home"));
const ChangeManagement = React.lazy(() => import("./pages/ChangeManagement"));
const LeanSixSigma = React.lazy(() => import("./pages/LeanSixSigma"));
const OperationalExcellence = React.lazy(() =>
  import("./pages/OperationalExcellence")
);
const SupplyChainOptimization = React.lazy(() =>
  import("./pages/SupplyChainOptimization")
);
const WarehouseDesign = React.lazy(() => import("./pages/WarehouseDesign"));
const WMSImplementation = React.lazy(() => import("./pages/WMSImplementation"));
const Automation = React.lazy(() => import("./pages/Automation"));
const BigDataManagement = React.lazy(() => import("./pages/BigDataManagement"));
const InterimITSupport = React.lazy(() => import("./pages/InterimITSupport"));
const CloudTransformation = React.lazy(() =>
  import("./pages/CloudTransformation")
);
const CloudDataAI = React.lazy(() => import("./pages/CloudDataAI"));
const CloudManagedService = React.lazy(() =>
  import("./pages/CloudManagedService")
);
const Desinging = React.lazy(() => import("./pages/Desinging"));
const Development = React.lazy(() => import("./pages/Development"));
const About = React.lazy(() => import("./pages/AboutUs"));
const Career = React.lazy(() => import("./pages/Career"));
const ApplyJob = React.lazy(() => import("./pages/ApplyJob"));
const DigitalMarketing = React.lazy(() => import("./pages/DigitalMarketing"));
const SCM = React.lazy(() => import("./pages/SCM"));
const OrcaleFusion = React.lazy(() => import("./pages/OrcaleFusion"));
const BusinessProcess = React.lazy(() => import("./pages/BusinessProcess"));
const SeniorLogistics = React.lazy(() => import("./pages/SeniorLogistics"));
const BlueYonder = React.lazy(() => import("./pages/BlueYonder"));
const Backoffice = React.lazy(() => import("./pages/Backoffice"));
const ITSupport = React.lazy(() => import("./pages/ITSupport"));
const WebDeveloper = React.lazy(() => import("./pages/WebDeveloper"));
const OracleFunctionalConsultant = React.lazy(() =>
  import("./pages/OracleFunctionalConsultant")
);
const Odoofunctionalconsultant = React.lazy(() =>
  import("./pages/Odoofunctionalconsultant")
);
const OdooDeveloper = React.lazy(() => import("./pages/OdooDeveloper"));
const ERPConsultant = React.lazy(() => import("./pages/ERPConsultant"));
const ContentWriter = React.lazy(() => import("./pages/ContentWriter"));
const SocialMediaManager = React.lazy(() =>
  import("./pages/SocialMediaManager")
);
const DataEntryOperator = React.lazy(() => import("./pages/DataEntryOperator"));
const DataAna = React.lazy(() => import("./pages/DataAna"));
const SAPBIConsultant = React.lazy(() => import("./pages/SAPBIConsultant"));
const SEOContentWriter = React.lazy(() => import("./pages/SEOContentWriter"));
const OrcaleFusionERP = React.lazy(() => import("./pages/OrcaleFusionERP"));
const OracleFusionTMSImplementation = React.lazy(() =>
  import("./pages/OracleFusionTMSImplementation")
);
const AccountingSupport = React.lazy(() => import("./pages/AccountingSupport"));
const Saudia = React.lazy(() => import("./pages/Saudia"));
const Dubai = React.lazy(() => import("./pages/Dubai"));
const Qatar = React.lazy(() => import("./pages/Qatar"));
const USA = React.lazy(() => import("./pages/USA"));
const Netherlands = React.lazy(() => import("./pages/Netherlands"));
const DataEntry = React.lazy(() => import("./pages/DataEntry"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div>
              <div className="flex justify-center items-center h-screen">
                <div className="flex flex-row gap-2">
                  <div
                    className="w-4 h-4 rounded-full bg-[#278083] animate-bounce"
                    style={{ animationDelay: "0.7s" }}
                  ></div>
                  <div
                    className="w-4 h-4 rounded-full bg-[#278083] animate-bounce"
                    style={{ animationDelay: "0.3s" }}
                  ></div>
                  <div
                    className="w-4 h-4 rounded-full bg-[#278083] animate-bounce"
                    style={{ animationDelay: "0.7s" }}
                  ></div>
                </div>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Change-Management" element={<ChangeManagement />} />
            <Route path="/Lean-&-six-sigma" element={<LeanSixSigma />} />
            <Route
              path="/Operational-Excellence"
              element={<OperationalExcellence />}
            />
            <Route
              path="/Supply-Chain-Optimization-Study"
              element={<SupplyChainOptimization />}
            />
            <Route path="/Warehouse-Design" element={<WarehouseDesign />} />
            <Route path="/WMS-Implementation" element={<WMSImplementation />} />
            <Route path="/Automation" element={<Automation />} />
            <Route
              path="/Big-Data-Management"
              element={<BigDataManagement />}
            />
            <Route path="/Interim-IT-Support" element={<InterimITSupport />} />
            <Route
              path="/Cloud-Transformation"
              element={<CloudTransformation />}
            />
            <Route path="/Cloud-Data-&-AI" element={<CloudDataAI />} />
            <Route
              path="/Cloud-Managed-Services"
              element={<CloudManagedService />}
            />
            <Route path="/About-Us" element={<About />} />
            <Route path="/Contact-Us" element={<ContactUs />} />
            <Route path="/Career" element={<Career />} />
            <Route path="/Desinging" element={<Desinging />} />
            <Route path="/Development" element={<Development />} />
            <Route path="/Digital-Marketing" element={<DigitalMarketing />} />
            <Route path="/ApplyJob" element={<ApplyJob />} />
            <Route path="/SCM" element={<SCM />} />
            <Route path="/OrcaleFusion" element={<OrcaleFusion />} />
            <Route path="/BusinessProcess" element={<BusinessProcess />} />
            <Route path="/SeniorLogistics" element={<SeniorLogistics />} />
            <Route path="/BlueYonder" element={<BlueYonder />} />
            <Route path="/SAP-TM-SCM-Functional-Consultant" element={<SCM />} />
            <Route
              path="/Oracle-Fusion-Functional-Consultant"
              element={<OrcaleFusion />}
            />
            <Route
              path="/Business-Process-Architect-Logistics"
              element={<BusinessProcess />}
            />
            <Route
              path="/Senior-Logistics-Engineer"
              element={<SeniorLogistics />}
            />
            <Route
              path="/Blue-Yonder-WMS-Dispatcher-Engineer"
              element={<BlueYonder />}
            />
            <Route path="/Backoffice-Assistant" element={<Backoffice />} />
            <Route path="/IT-Support-Specialist" element={<ITSupport />} />
            <Route path="/Web-Developer" element={<WebDeveloper />} />
            <Route
              path="/Oracle-Functional-Consultant"
              element={<OracleFunctionalConsultant />}
            />
            <Route
              path="/Odoo-Functional-Consultant"
              element={<Odoofunctionalconsultant />}
            />
            <Route path="/Odoo-Developer" element={<OdooDeveloper />} />
            <Route path="/ERP-Consultant" element={<ERPConsultant />} />
            <Route path="/Content-Writer" element={<ContentWriter />} />
            <Route
              path="/Social-Media-Manager"
              element={<SocialMediaManager />}
            />
            <Route
              path="/Data-Entry-Operator"
              element={<DataEntryOperator />}
            />
            <Route path="/Data-Analyst" element={<DataAna />} />
            <Route path="/SAP-BI-Consultant" element={<SAPBIConsultant />} />
            <Route path="/SEO-Content-Writer" element={<SEOContentWriter />} />
            <Route
              path="/Oracle-Fusion-EPM-Implementation-Support-Specialist"
              element={<OrcaleFusionERP />}
            />
            <Route
              path="/Oracle-Fusion-TMS-Implementation-Project-Manager"
              element={<OracleFusionTMSImplementation />}
            />
            <Route
              path="/Admin-Accounting-Support"
              element={<AccountingSupport />}
            />
            <Route path="/Sales-Manager" element={<SalesManager />} />
            <Route path="/Travel-Agent" element={<TravelAgent />} />
            <Route path="/Technical-Buyer" element={<TechnicalBuyer />} />
            <Route path="/Saudia" element={<Saudia />} />
            <Route path="/Dubai" element={<Dubai />} />
            <Route path="/Qatar" element={<Qatar />} />
            <Route path="/USA" element={<USA />} />
            <Route path="/Netherlands" element={<Netherlands />} />
            <Route path="/Not-Found" element={<NotFound />} />
          </Routes>
          <Layout />
        </Suspense>
      </BrowserRouter>
    </>
  );
}
